import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import Text from 'common/components/Text';
import Container from 'common/components/UI/Container';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import { Icon } from 'react-icons-kit';
import { paperPlane } from 'react-icons-kit/fa/paperPlane';
import { ic_phone } from 'react-icons-kit/md/ic_phone';
import { ic_place } from 'react-icons-kit/md/ic_place';
import Button from 'common/components/Button';
import Box from 'common/components/Box';

import AppImage from 'common/assets/image/crypto/app.svg';
import PlaystoreImage from 'common/assets/image/crypto/playstore.svg';
import LogoImage from 'common/assets/image/AppClassic/logo.png';
import {
	AboutUs, ContactInfo, FooterWidget, Grid, InfoItem, ButtonWrap
} from './footer.style';

const FooterTop = (props) => {
	const data = useStaticQuery(graphql`
  query {
		appCreative2Json {
				footerTop {
					widgets {
						id
						list {
							id
							link
							title
						}
						title
					}
					contactInfo {
						address
						email
						openingTime
						phone
						title
					}
					about {
						logo {
							publicURL
						}
						text
					}
				}
			}
    }
  `);

  const { appCreative2Json: footerTop } = data;

  const items = footerTop?.footerTop;
  const date = new Date();
  const year = date.getFullYear();

    const { about, widgets, contactInfo } = items || {};
    return (
			<Container {...props}>
				<Grid>
					<AboutUs>
						<Link to="/">
							<Image src={LogoImage} alt="H2Zero | Bermuda" />
						</Link>
						<Heading
              className="appDownload"
              content="Download The App"

            />
            <Box className="imageWrapper">
              <a href="https://apps.apple.com/us/app/h2zero/id1602519505">
                <Image src={AppImage} alt="App Image" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.h2zero&hl=en_US&gl=GB">
                <Image src={PlaystoreImage} alt="PlaystoreImage Image" />
              </a>
            </Box>
					</AboutUs>
					{widgets?.length ? widgets?.map((item) => (
						<FooterWidget key={item?.id}>
							<h4>{item?.title}</h4>
							{item?.list?.length ?
								<ul>
										{item?.list?.map((item) => (
										<li className="widgetListItem" key={item.id}>
												<Link to={item?.link}>{item?.title}</Link>
										</li>
										))}
								</ul>
							:
							""
							}
						</FooterWidget>
					))
					:
					""
					}
					<ContactInfo>
						<Heading as="h4" content={contactInfo?.title} />
						<InfoItem>
							<Icon icon={ic_place} size={24} />
							<Text content={contactInfo?.address} />
						</InfoItem>
						<InfoItem>
							<Icon icon={ic_phone} size={26} className="phone-icon" />
							<div>
									<Text
											className="phone-number"
											content={contactInfo?.phone}
									/>
									<Text content={contactInfo?.openingTime} />
							</div>
						</InfoItem>
						<InfoItem>
							<Icon icon={paperPlane} size={22} />
							<Text content={contactInfo?.email} />
						</InfoItem>

					</ContactInfo>
				</Grid>
			</Container>
    );
};

export default FooterTop;
