import React, {Fragment} from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from 'common/theme/appclassic';
import { ResetCSS } from 'common/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from 'containers/AppClassic/Navbar';
import Banner from 'containers/AppClassic/Banner';
import Customer from 'containers/AppClassic/Customer';
import KeyFeatures from 'containers/AppClassic/KeyFeatures';
import { Modal } from '@redq/reuse-modal';
import AppSlider from 'containers/AppClassic/AppSlider';
import Features from 'containers/AppClassic/Features';
import DesignedAndBuilt from 'containers/AppClassic/DesignedAndBuilt';
import FeatureTab from 'containers/AppClassic/FeatureTab';
import Pricing from 'containers/AppCreative/Pricing';
import Testimonial from 'containers/AppClassic/Testimonial';
import Faq from 'containers/AppClassic/Faq';
import JoinTrail from 'containers/AppClassic/JoinTrail';
import Footer from 'containers/AppCreative2/Footer';
import Dashboard from 'containers/appModern/Dashboard';
import Subscribe from '../containers/AgencyModern/Subscribe';
import '@redq/reuse-modal/es/index.css';
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from 'containers/AppClassic/appClassic.style';

import Seo from 'components/seo';

const AppClassic = () => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Seo title="H2Zero | Tank Water Level Monitoring Service" />
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            <Navbar />
          </Sticky>
          <ContentWrapper>
            <Modal />
            <Banner />

            <Customer />
            <KeyFeatures />
           {/* <AppSlider /> */}
            <Features />
            {/* <Dashboard /> */}
            {/* <DesignedAndBuilt /> */}
            <FeatureTab />
            <Pricing />
            <Testimonial />
            <Faq />

            <JoinTrail />
          </ContentWrapper>
         {/* <Subscribe />*/}
          <Footer />
        </AppWrapper>
      </>
    </ThemeProvider>
  );
};
export default AppClassic;
